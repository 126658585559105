import { Injectable, OnInit } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DirtyComponent } from './dirty-component';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent>, OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return !!confirm(
      'Vous avez des changements non enregistrés! Si vous quittez, vos modifications seront perdues.',
    );
  }
}
